<template>
  <div class="meals">
    <div class="page-header">
      <h1>{{ $t('Menu Management') }} > {{ $t('Set Meals') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Meal') }}</h2>
        <router-link :to="{ name: 'AddSetMeal' }">
          <el-button class="action-button" type="primary">{{ $t('Add Set Meal') }}</el-button>
        </router-link>
      </div>

      <div class="meal-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Photo') }}</th>
              <th scope="col">{{ $t('Set Meal Name') }}</th>
              <th scope="col">{{ $t('Regular Price') }}</th>
              <th scope="col">{{ $t('Status') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="meal in meals">
              <td class="meal-image-td">
                <div class="meal-image-wrapper">
                  <img class="meal-image img-fluid" v-if="meal.image" :src="meal.image" />
                  <img class="meal-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td>{{ meal.name }}</td>
              <td>${{ meal.regular_price }}</td>
              <td v-if="meal.status == 1">{{ $t('Available')}}</td>
              <td v-else>{{ $t('Not Available')}}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'EditMeal', params: {id: meal.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';
import ItemFilter from '@/components/Filter.vue';

export default {
  name: 'Meals',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadMeals();
  },
  methods: {
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'mealName':
        if(this.langcode === 'zh'){
          searchField['meal_name_zh'] = form.filterValue;
        }else{
          searchField['meal_name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalMeals);
        this.searchMeals = newItems;
        this.meals = newItems;
        break;
      }
    },
    onCheckboxChange(){
      const self = this; //Use self to replace this in filter function
      const targetCategories = self.targetCategories;
      if(self.searchMeals){
        const newItems = self.searchMeals.filter(function(item){
          let itemName = '';
          const itemCateogires = item.meal_categories;
          for(let i = 0; i < itemCateogires.length; i++){
            const itemCateogire = itemCateogires[i];
            if(self.langcode === 'zh'){
              if(targetCategories.includes(itemCateogire.categorie_name_zh)){
                return true;
              }
            }else{
              if(targetCategories.includes(itemCateogire.categorie_name_en)){
                return true;
              }
            }
          }
        });
        self.meals = newItems;
      }else{
        const newItems = self.orignalMeals.filter(function(item){
          let itemName = '';
          const itemCateogires = item.meal_categories;
          for(let i = 0; i < itemCateogires.length; i++){
            const itemCateogire = itemCateogires[i];
            if(self.langcode === 'zh'){
              if(targetCategories.includes(itemCateogire.categorie_name_zh)){
                return true;
              }
            }else{
              if(targetCategories.includes(itemCateogire.categorie_name_en)){
                return true;
              }
            }
          }
        });
        self.meals = newItems;
      }
      //If do not had any selected categoires ,reset it
      if(targetCategories.length === 0 ){
        if(self.searchMeals){
          self.meals = self.searchMeals;
        }else{
          self.meals = self.orignalMeals;
        }
      }
    },
    async onTabChange(){
      const activeName = this.activeName;
      if(activeName !== 'All'){
        this.loadLimitMeals(activeName);
      }else{
        this.meals = this.orignalMeals;
      }
    },
    async loadLimitMeals(type){
      const searchFields = {
        'meal_published': '',
      }
      if(type === 'Published'){
        if(this.searchMeals){
          const newItems = this.searchMeals.filter(function(meal){
            return meal['meal_published'] == 1;
          });
          this.meals = newItems;
        }else{
          const newItems = this.orignalMeals.filter(function(meal){
            return meal['meal_published'] == 1;
          });
          this.meals = newItems;
        }
      }
      if(type === 'Unpublished'){
        if(this.searchMeals){
          const newItems = this.searchMeals.filter(function(meal){
            return meal['meal_published'] == 0;
          });
          this.meals = newItems;
        }else{
          const newItems = this.orignalMeals.filter(function(meal){
            return meal['meal_published'] == 0;
          });
          this.meals = newItems;
        }
      }
      if(type === 'All'){
        if(this.searchMeals){
          this.meals = searchMeals;
        }else{
          this.meals = orignalMeals;
        }
      }
    },
    async loadMeals(){
      try{
        const loginInfo = Common.getLoginInfo();
        const meals = await Menu.loadAllSetMeals(this.apiUrl, loginInfo);
        this.orignalMeals = meals;
        this.meals = meals;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      activeName: 'All',
      meals: [],
      searchMeals: null, //Keep the tab result
      orignalMeals: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}
.meal-image-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  .meal-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.meal-image-td{
  width: 20%;
}
</style>
